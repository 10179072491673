jQuery(function ($) {
  $(document).ready(function () {
    
    var popup = $("#cookie_popup");
    var c_name = "casinobonusar.nu";

    if (!getCookie(c_name)) {
      
      $('#back-to-top').hide();
      $('.buttonizer-button').hide();
      popup.show().find("button").click(function () {
        $('#back-to-top').css('display', 'flex');

        $('.buttonizer-button').show(); 
        setCookie(c_name, true, 365);
        popup.hide();
      });
    }

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return null;
    }
    function deleteCookie(cookiename) {
      var d = new Date();
      d.setDate(d.getDate() - 1);
      var expires = "expires=" + d;
      var value = "";
      document.cookie = cookiename + "=" + value + ";" + expires + ";path=/";
    }

    // TOPLIST INFO TOGGLE FUNCTION
    // $('.content-bonuses').on('click', 'i.toplist-info-toggle', function () {
    //   var parentArticle = $(this).closest('article')
    //   var optionsPanel = parentArticle.find('.toplist-options').first()

    //   // toggling cross
    //   if ($(this).hasClass('fa-info-circle')) {
    //     $(this).removeClass('fa-info-circle').addClass('fa-times')
    //   } else {
    //     $(this).addClass('fa-info-circle').removeClass('fa-times')
    //   }

    //   // dropdown according to mobile or desktop version
    //   if (isMobile() == "true") {
    //     $(this).parent().parent().children('.toplist-bonus, .toplist-points, .toplist-review, .toplist-button, .toplist-recension').toggle('slow');
    //     $(this).parent().parent().children('.toplist-options').toggle('slow').css('display', 'flex');
    //   } else {
    //     if (!optionsPanel.is(':visible')) {
    //       optionsPanel.slideDown({ start: function () {
    //         $(this).css({ display: "flex" })
    //       }})
    //     }else {
    //       optionsPanel.slideUp({ end: function () {
    //         $(this).css({ display: "none" })
    //       }});
    //     }
    //   }
    // })

    function isMobile() {
      if (jQuery(window).width() < 1024) {
          return "true"
      } else {
          return "false"
      }
    }


    
    function cookieClickHandle() {
      $('#sign-up-form').css("display", "block");
    }    
    $('.cookie-cta').on('click', cookieClickHandle);

    $('#af-form-782936933 input.text[name="name"]').attr('placeholder', 'Skriv ditt namn');
    $('#af-form-782936933 input.text[name="email"]').attr('placeholder', 'Skriv in din e-post');
    $('#af-form-782936933 input.text').attr('required', true);
    $('#af-form-782936933 input.text[name="email"]').attr('type', 'email');
    $('#af-form-782936933 input[type="checkbox"]').attr('required', true);  

    function closeSignUpForm() {
      $('#sign-up-form').css("display", "none");
    }    
    $('.sign-up-close-js').on('click', closeSignUpForm);

    
    function expandSignUpForm(e) {
      $(e.currentTarget).attr('autocomplete', 'off');
      $('#af-form-782936933 .af-body .af-element:first-child').css("display", "block");
      $('#af-form-782936933 .af-header').css("display", "block");
      $('#af-form-782936933 .af-body .af-element-checkbox').css("display", "block");
    }    
    $('#af-form-782936933 input.text').on('focus', expandSignUpForm);

    // Resize sidebar iframe to fit width
    function resizeSidebarIframe() {
      var sidebarWidth = $('.sidebar-after-awards').width();
      if( sidebarWidth !== null ) {
        var iframeWidth = sidebarWidth; console.log(iframeWidth);
        var iframeHeight = Math.floor( iframeWidth * 0.56 );
        $('.sidebar-after-awards').find('iframe').css({ 'width' : iframeWidth+'px', 'height' : iframeHeight+'px' });
        $('.sidebar-after-awards').find('iframe').attr({ 'width' : iframeWidth+'px', 'height' : iframeHeight+'px' });
      }
      // Resize the iframe media that is not necessarily in sidebar.
        var customiframeWidth = $(".iframe-class").width();
        if( customiframeWidth !== null) {
          customiframeWidth = $(".iframe-class").parent().width();
          var customiframeHeight = Math.floor (customiframeWidth * 0.56);
          $('.iframe-class').css({ 'width' : customiframeWidth+'px', 'height' : customiframeHeight+'px' });
          $('.iframe-class').attr({ 'width' : customiframeWidth+'px', 'height' : customiframeHeight+'px' });
        }
    }
    resizeSidebarIframe();
    $( window ).resize( resizeSidebarIframe );
  });
})